<template>
  <div>
    <k-form-build :value="jsonData" ref="formFill" />
  </div>
</template>

<script>
import * as Request from '@/api/starter';
import store from 'storejs';
import { Button } from 'ant-design-vue';
import Vue from 'vue';
Vue.use(Button);

export default {
  name: 'FormFill',
  data() {
    return {
      jsonData: {}
    };
  },
  created() {
    const procDefId = this.$route.params.procDefId;
    const token = this.$route.params.token;
    const formId = this.$route.params.formId;
    store.set('FormVueToken', token);
    Request.globalFormData({ processDefinitionId: procDefId }).then(res => {
      this.jsonData = JSON.parse(res.data);
      Request.getAndLoadData({ id: formId }).then(res => {
        this.$refs.formFill.form.setFieldsValue(JSON.parse(res.data.formData));
      });
    });
  }
};
</script>



<style scoped>
</style>
